.page {
  background-color: #f6f5ff;
  position: relative;
  width: 375px;
  overflow: hidden;
}
.page .image_1 {
  width: 375px;
  height: 85px;
}
.page .section_1 {
  padding: 18px 0 10px 0;
}
.page .section_1 .box_1 {
  width: 262px;
  margin: 0 97px 0 16px;
}
.page .section_1 .box_1 .group_1 {
  border-radius: 14px;
  background-image: url(./img/a5629eb042314df3815b1ad02be87beb_mergeImage.png);
}
.page .section_1 .box_1 .group_1 .box_2 {
  border-radius: 14px;
  width: 68px;
  height: 68px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.page .section_1 .box_1 .text-group_1 {
  margin: 10px 0 9px 0;
}
.page .section_1 .box_1 .text-group_1 .text_1 {
  overflow-wrap: break-word;
  color: #000;
  font-size: 18px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
  margin-right: 129px;
}
.page .section_1 .box_1 .text-group_1 .text_2 {
  overflow-wrap: break-word;
  color: #5a5476;
  font-size: 14px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  margin-top: 4px;
}
.page .section_1 .text-wrapper_1 {
  background-color: #5233ff;
  border-radius: 10px;
  align-self: center;
  margin-top: 14px;
  padding: 9px 155px 9px 156px;
}
.page .section_1 .text-wrapper_1 .text_3 {
  overflow-wrap: break-word;
  color: #fff;
  font-size: 16px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: right;
  white-space: nowrap;
  line-height: 22px;
}
.page .section_1 .text-wrapper_2 {
  background-color: #e5e3fc;
  margin-top: 14px;
  padding: 9px 12px 9px 111px;
}
.page .section_1 .text-wrapper_2 .text_4 {
  overflow-wrap: break-word;
  color: #333;
  font-size: 16px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: right;
  white-space: nowrap;
  line-height: 22px;
}
.page .section_1 .text-wrapper_2 .text_5 {
  overflow-wrap: break-word;
  color: #333;
  font-size: 16px;
  letter-spacing: 5px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: right;
  white-space: nowrap;
  line-height: 22px;
}
.page .section_1 .text-wrapper_2 .text_6 {
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 0.7);
  font-size: 12px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: right;
  white-space: nowrap;
  line-height: 17px;
  padding: 0 20px;
  margin: 2px 0 0 56px;
}
.page .section_1 .box_3 {
  width: 359px;
  margin: 14px 0 0 16px;
}
.page .section_1 .box_3 .box_4 {
  border-radius: 12px;
  background-image: url(./img/998b749545054cf984e5a53c43804b33_mergeImage.png);
  width: 140px;
  height: 300px;
}
.page .section_1 .box_3 .box_5 {
  border-radius: 12px;
  background-image: url(./img/ec4631dc304a482e92cf3a1d9be8d60b_mergeImage.png);
  width: 140px;
  height: 300px;
}
.page .section_1 .box_3 .box_6 {
  border-radius: 12px;
  background-image: url(./img/9ffe63b754f442cdba543ab67a9ed235_mergeImage.png);
  width: 63px;
  height: 300px;
}
.page .section_1 .box_3 .demo-chat-swiper {
  height: 400px;
}
.page .section_1 .box_3 .demo-chat-swiper .box-item {
  width: 180px;
  border-radius: 12px;
  background-size: cover;
  background-position: center;
  margin: 0 auto;
  height: 386px;
}
.page .section_1 .text_7 {
  overflow-wrap: break-word;
  color: #000;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
  margin: 16px 295px 0 16px;
}
.page .section_1 .paragraph_1 {
  width: 343px;
  height: 134px;
  overflow-wrap: break-word;
  color: #4d4b56;
  font-size: 14px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: justify;
  line-height: 22px;
  align-self: center;
  margin-top: 6px;
}
.page .section_1 .text_8 {
  overflow-wrap: break-word;
  color: #5a5476;
  font-size: 12px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 17px;
  margin: 15px 155px 0 16px;
}
.page .section_1 .text_9 {
  overflow-wrap: break-word;
  color: #5a5476;
  font-size: 12px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 17px;
  margin: 2px 161px 0 16px;
}
.page .image_2 {
  width: 375px;
  height: 34px;
  margin-top: -1px;
}
